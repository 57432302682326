import React from "react"
import { Layout } from "../components/common/layout"
import { Banner } from "../components/common/banner"

const SolutionsPage = () => (
    <Layout infoPage={{ page: "Diagnóstico Gratuito" }}>
        <main className="diagnostico">
            <Banner>
                <div className="content">
                    <div className="image-box">
                        <h1 className="title bold">
                            Diagnóstico da
                            <br />
                            Gestão de Frota
                        </h1>
                    </div>
                    <span
                        className="subtitle medium"
                        style={{ fontSize: "36px" }}
                    >
                        Avalie a saúde da sua operação logística
                        <br />
                        Responda as questões abaixo e receba em seu e-mail o
                        <br /> Diagnóstico completo da Gestão de Frota
                    </span>
                    <a
                        className="button medium"
                        href="/diagnostico-gratuito"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Solicite o Diagnóstico Gratuito
                    </a>
                </div>
            </Banner>
        </main>
    </Layout>
)

export default SolutionsPage
